import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH } from 'constants/sizes';
import { Article } from 'UI';

const Container = styled(Article)`
  grid-column: 3/9;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export default Container;
