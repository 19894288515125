import React, { FC, ReactNode } from 'react';

import {
  Container, Title, Header, Description,
} from './styled';

interface IArticle {
  title: string;
  description?: string;
  children: ReactNode;
}

const CookiePageWrapper: FC<IArticle> = ({ title, description = '', children }) => (
  <Container withPaddings>
    <Header>Privacy</Header>
    <Title>{title}</Title>
    <Description>{description}</Description>

    {children}
  </Container>
);

export default CookiePageWrapper;
