import React, { useState, useEffect, FC } from 'react';

import { SETTING_TYPE } from 'constants/cookie';

import Container from './styled';
import text from './doc';

interface ICookieSettings {
  marketing: boolean;
  performance: boolean;
  security: boolean;
  system: boolean;
}

const CookieList: FC = () => {
  const [data, setData] = useState<string>('');

  const getText = () => {
    let newText = text || '';
    const localData = localStorage?.getItem('cookie-settings');
    const values = JSON.parse(localData) as ICookieSettings;

    Object.values(SETTING_TYPE).forEach((key) => {
      if (values && values[key]) {
        newText = newText.replace(`[${key}]`, '<p style="color: var(--red)">Currently disabled</p>');
      } else {
        newText = newText.replace(`[${key}]`, '');
      }
    });

    setData(newText);
  };

  useEffect(() => {
    getText();
  }, []);

  return <Container text={data} title="" />;
};

export default CookieList;
