const text = `
# Essential

These cookies are essential for websites and their features to work properly.

| Name | Source | Purpose | Expires |
| --- |--- | --- | --- |
| _AVESTA_ENVIRONMENT | MailChimp | This enables an email marketing automation service. | When you close your browser |
| MCPopupClosed | MailChimp | This enables an email marketing automation service. | 1 year |
| MCPopupSubscribed | MailChimp | This enables an email marketing automation service. | 3 month |

# Security

These cookies help us to maintain a secure environment in order to protect both your and our interests and to identify potential threats.

[security]

# Performance

These cookies increase the usability of sites and provide them with additional functions.

Example: Cookies that store search results, language settings, or font size.

[performance]

# Marketing

These cookies allow us to measure and optimize the user experience of our website as well as deliver more relevant and effective marketing.

[marketing]

| Name | Source | Purpose | Expires |
| --- |--- | --- | --- |
| _ga | Google Analytics | Used to distinguish users. | 2 years |
| _gat | Google Analytics | Used to distinguish users. | 24 hours |
| _gld | Google Analytics | Used to throttle request rate. | 1 minute |
| _ym_uld | Yandex.Metrica | Used for identifying site users. | 1 year |
| _ym_d | Yandex.Metrica | Used to store the date of the user's first site session. | 1 year |
| _ym_isad | Yandex.Metrica | Used to determine whether a user has ad blockers. | 2 days |
`;

export default text;
