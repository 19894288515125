import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import CookieList from 'components/blocks/CookiePage/CookieList/CookieList';
import CookiePageWrapper from 'components/blocks/CookiePage/CookiePageWrapper/CookiePageWrapper';

const description = `
  WayRay® puts small files (known as ‘cookies’) onto your computer to collect
  information about how you browse the site for various purposes described below.

  To adjust your cookie settings at any time please go to [Cookie settings](/cookie-settings).

  On this website we use the following cookies:
`;

const IndexPage: FC<IPageProps> = () => (
  <>
    <Helmet>
      <title>WayRay | List of cookies</title>
      <meta property="og:title" content="WayRay | List of cookies" />
    </Helmet>
    <CookiePageWrapper title="List of cookies" description={description}>
      <CookieList />
    </CookiePageWrapper>
  </>
);

export default IndexPage;
