import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

import { MAX_TABLET_WIDTH, MAX_MOB_WIDTH } from 'constants/sizes';

import { Layout, SectionTitle } from 'UI';

export const Header = styled(SectionTitle)`
  grid-column: 3/7;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Title = styled.h2`
  grid-column: 3/7;
  margin-bottom: 0.5em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Description = styled(Markdown)`
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
  grid-column: 3/9;
  padding-bottom: 5rem;

  a {
    color: var(--red);
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Container = styled(Layout)`
  margin: 5em 0;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin: 0;
  }
`;
